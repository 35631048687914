@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

$desktop-1920: 1920px;
$desktop-1366: 1366px;
$tablet-768: 768px;
$pixel2-411: 411px;
$iPhoneX-375: 375px;
$mobile-360: 360px;
$mobile-375: 375px;
$mobile-360: 360px;

body {
  background-color: rgb(38, 50, 43
  );


.background {
  background-image: url("./assets/trees.jpg");
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }

  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .title-top {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 8% 0 0 0;
    font-family: 'Roboto', sans-serif;

    .branch-icon {
      align-self: center;
    }

    .name {
      align-self: center;
      font-weight: bold;
      font-size: 3em;
      font-weight: bold;
      color: rgb(38, 50, 43);
      margin: 0% 0 0% 0;
    }

    .subtitle {
      font-size: 1em;
      font-weight: 300;
      color: rgb(38, 50, 43);
    }

    .down-caret {
      width: 100vw;
      height: 6vh;
      display: flex;
      justify-content: center;
      margin: 14% 0 0% 0;
      opacity: .5;
    }
  }

  .project-section {
    width: 94vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    @media (max-width: $desktop-1920) {
      height: 80vh;
    }
    @media (max-width: $desktop-1366) {
      height: 45vh;
    }
    @media (max-width: $pixel2-411) {
      height: 80vh;
    }

    .project-title {
      width: 100%;
      font-size: 2em;
      color: rgb(38, 50, 43);
      font-weight: bold;
      text-align: center;
      margin: 2% 0% 0% 0%;
      font-size: 2em;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
    }

    .project-list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      @media (max-width: $tablet-768) {
        height: 40vh;
      }
      @media (max-width: $pixel2-411) {
        flex-direction: column;
        height: 100%;
        margin: 4% 0 0 0;
      }


      .project-box {
        height: 50%;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid rgb(46, 86, 61);
        background-color: rgba(86, 104, 86, 0.6);
        padding: .5%;
        border-radius: 8px;
        @media (max-width: $pixel2-411) {
          width: 100%;
          margin: 0 0 4% 0;
        }
        // a {
        //   height: 100%;
        //   width: 100%;

          .project {
            height: 100%;
            width: 100%;
          }
        // }
      }
    }

  }

  .about-section {
    border: 2px solid rgb(46, 86, 61);
    background-color: rgba(86, 104, 86, 0.6);
    color: rgb(38, 50, 43);
    height: auto;
    width: 94vw;
    margin: 8% auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: $tablet-768) {
     margin: 14% auto;
    }

    .about-title {
      width: 100%;
      height: 20%;
      font-size: 2em;
      font-weight: bold;
      text-align: center;
      margin: 0 0 3% 0;
      padding: 4% 0 0 0;
      font-size: 2em;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
    }

    .about-content {
      width: 60%;
      height: 40%;
      font-size: 1.5em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
      margin: 0 auto;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      @media (max-width: $tablet-768) {
        width: 80%;
      }
      @media (max-width: $pixel2-411) {
        width: 90%;
        font-size: 1em;
      }
    }

    .skills-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .skills-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 8% 0 0% 0;

        .skill {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          @media (max-width: $tablet-768) {
            width: 5%;
          }
          @media (max-width: $pixel2-411) {
            width: 25px;
          }
          .skill-name {
            @media (max-width: $tablet-768) {
              margin: 0% 0 10% 0;
              font-size: .8em;
            }
            @media (max-width: $pixel2-411) {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer-section {
    width: 100vw;
    height: auto;
    background-color: rgb(23, 22, 22);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1% 0 1% 0;

    .social-media-footer {
      width: 16vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-content: space-around;
      margin: 1% 0 0 0;
      @media (max-width: $tablet-768) {
        width: 24vw;
      }
      @media (max-width: $pixel2-411) {
        width: 54vw;
      }

      .social-icon {
        color: white;
        @media (max-width: $pixel2-411) {
          .fa-2x {
            font-size: 1.2em !important;
          }
        }
        a,
        a:hover,
        a:visited,
        a:active {
          color: white;
        }
      }
    }
  }
}
}